import mobileDevice from 'ismobilejs';

import store from '../store';
import { getRooms } from '../utils/getVCSettings';
import ChatConnection from './ChatConnection';
import {
  initApp,
  uiHide,
  uiShow,
  clearStore
} from '../ducks/global/app.action';
import { addRoom, updateRoom, deleteRoom } from '../ducks/global/rooms.action';
import { saveStore } from '../utils/storage';

import { initRoom } from '../ducks/global/rooms.reducer';
import { activateCb } from '../ducks/chatBox/chatBox.action';
import {
  initSb,
  deactivateSb
} from '../ducks/speechBubble/speechBubble.action';
import { openFb } from '../ducks/floatingButton/floatingButton.action';
import { activateCTA } from '../ducks/callToAction/callToAction.action';
import {
  activateRs,
  deactivateRs
} from '../ducks/roomsSelector/roomsSelector.action';
import { activateContactVia } from '../ducks/contactVia/contactVia.action';
import {
  statementsInIntegers,
  chatTypes,
  IS_MOBILE_WIDTH
} from '../config/constants';
import {
  getProp,
  isWindowEmbedded,
  getRoomNameFromLocation
} from 'utils/helpers';
import { fbAdhoc } from 'utils/adhoc';
import SETTING from '../helpers/mobile-desktop-settings';
import { getSettings } from '../utils/requests';
import logger from '../utils/logger';
import throttle from 'lodash/throttle';

const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;

/**
 * Start up initialization logic with configurations
 */
class Configurator {
  constructor() {
    this.connect = null;
    this.init();
  }

  /**
   * Initialize Application
   * @returns {Promise<void>}
   */
  async init() {
    const state = store.getState();
    const { global } = state;
    const { chosenWebSettingsIndex } = global;
    const vcSettingsRooms = getRooms();
    const webSettings = await getSettings(vcSettingsRooms);
    this.mergeSettings(webSettings, vcSettingsRooms);

    const chosenSettings = webSettings?.[chosenWebSettingsIndex || 0] ?? null;
    if (!chosenSettings) {
      return;
    }

    const settings = this._prepareDefaultSettingsProps(chosenSettings.data);
    const meta = Object.keys(chosenSettings).reduce((accumulator, propName) => {
      if (propName !== 'data') accumulator[propName] = chosenSettings[propName];
      return accumulator;
    }, {});

    store.dispatch(
      initApp({
        meta,
        settings,
        webSettings
      })
    );
    this.settingsCompositor({
      meta,
      settings,
      webSettings
    });
    this.addListeners(meta.has_ajax_routing);
    window.VC_APP_INITALIZED = true;
  }

  /**
   * Merge webSettings data with vcSettings data
   * @param webSettings
   * @param vcSettings
   */
  mergeSettings = (webSettings, vcSettings) => {
    if (webSettings === null || !vcSettings.length) {
      return;
    }
    webSettings.forEach(setting => {
      const vcSetting = vcSettings.find(
        vcSetting => vcSetting.id === setting.account_public_id
      );
      if (!vcSetting) {
        return;
      }
      setting.name = vcSetting.name;
      setting.vcSettings = vcSetting;
      if (vcSetting.accountId) {
        setting.superId = vcSetting.accountId;
      }
    });
  };

  /**
   * Prepare Application Settings
   * @param props
   */
  settingsCompositor = (props = {}) => {
    const { webSettings } = props;
    const state = store.getState();
    const { global } = state;

    // if (isWindowEmbedded()) {
    //   store.dispatch(uiHide());
    //   logger.info(
    //     'VisitorChat UI is hidden. The connection to the chat server is not established.'
    //   );
    //   return;
    // } else {
    //   store.dispatch(uiShow());
    // }
    store.dispatch(uiShow());

    if (
      webSettings.length > 1 &&
      typeof global.chosenWebSettingsIndex !== 'number'
    ) {
      this.stepWithRoomsSelector(props);
    } else {
      this.stepWithoutRoomsSelector(props);
    }
  };

  stepWithRoomsSelector = (props = {}) => {
    const { settings } = props;
    const { roomsSelector } = store.getState();
    const { getFBAvailability } = fbAdhoc();
    const fbIsAvailable = getFBAvailability({ store });

    store.dispatch(activateRs());

    if (fbIsAvailable) {
      const isCTAEnabled =
        settings[`sb${isPhone ? '_mobile' : ''}_enabled`] === 'on';
      const isCTAShowPermanentlyDesktop =
        settings.sb_show_permanently_desktop === 'on';
      const isCTAShowPermanentlyMobile =
        settings.sb_show_permanently_mobile === 'on';
      const isCTAShowPermanently = isPhone
        ? isCTAShowPermanentlyMobile
        : isCTAShowPermanentlyDesktop;

      if (isCTAEnabled) {
        store.dispatch(activateCTA(isCTAShowPermanently));
      }

      if (!roomsSelector.open) {
        store.dispatch(openFb());
      }
    }
  };

  stepWithoutRoomsSelector = (props = {}) => {
    const { meta, settings, webSettings } = props;
    const state = store.getState();
    const { chosenWebSettingsIndex, roomsSelector } = state;

    if (roomsSelector.active) {
      store.dispatch(deactivateRs());
    }

    this.runApp({
      meta,
      settings,
      chosenWebSettings: webSettings[chosenWebSettingsIndex || 0]
    });
  };

  /**
   * Start up Application / Socket Connection
   * @param props
   * @returns {Promise<void>}
   */
  async runApp(props = {}) {
    const { meta, settings, chosenWebSettings } = props;
    const getState = store.getState;
    const roomSettings = this.parseRoomSettings({ meta, chosenWebSettings });
    const { room, advertName, dealerName } = roomSettings;
    const { global, rooms = [] } = getState();
    const roomInfo = rooms.find(r => r.room === room);
    const referrer = (() => {
      const referrerHostname = document.referrer.length
        ? new URL(document.referrer).hostname
        : null;
      if (
        referrerHostname &&
        (referrerHostname === window.location.hostname ||
          referrerHostname === 'chat.visitor.chat')
      ) {
        return null;
      }
      return referrerHostname ? document.referrer : null;
    })();

    this.componentsActivator(settings, meta);
    logger.debug(`Current rooms ${JSON.stringify(rooms)}`);
    logger.debug(`Global active room: ${global.activeRoom}`);
    logger.verbose('Checking for active chats...');
    // Clear out rooms from store that have no active chats (see VC-304)
    rooms.forEach(room => {
      if (
        room.status === chatTypes.STATUS_NEW &&
        room.room !== global.activeRoom
      ) {
        logger.debug(`Removing room ${room.room} - inactive`);
        store.dispatch(deleteRoom(room.room));
      }
    });
    if (!roomInfo) {
      logger.verbose('Room info not found, adding...');
      store.dispatch(addRoom(room, roomSettings));
    } else if (
      roomInfo.advertName !== advertName ||
      roomInfo.dealerName !== dealerName
    ) {
      store.dispatch(updateRoom(roomSettings.room, { advertName, dealerName }));
    }
    logger.debug(`Got meta data: ${JSON.stringify(meta)}`);
    this.connect = await new ChatConnection({
      server: meta.host,
      pageUrl: window.location.href,
      userId: getProp(getState(), 'global.userId', null),
      referrer,
      configurator: this
    });
  }

  /**
   * Destroy Application
   */
  destroyApp = () => {
    store.dispatch(clearStore());
    this.disconnect();
  };

  /**
   * Disconnect WS
   */
  disconnect = () => {
    if (typeof this.connect._destroy === 'function') {
      this.connect._destroy();
    }
  };

  /**
   *
   * @param props
   * @returns {*}
   */
  parseRoomSettings = (props = {}) => {
    const { meta, chosenWebSettings } = props;
    const room = getRoomNameFromLocation();
    const domainId =
      getProp(meta, 'domain_id', null) ||
      getProp(chosenWebSettings, 'domainId', null);
    const accountId = getProp(meta, 'account_id', null);
    const accountPublicId =
      getProp(meta, 'account_public_id', null) ||
      getProp(chosenWebSettings, 'accountPublicId', null);
    const superId = getProp(meta, 'superId', null);
    const groupId =
      getProp(meta, 'group_id', null) ||
      getProp(chosenWebSettings, 'groupId', null);
    const dealerName = getProp(chosenWebSettings, 'name', null);
    const advertName = getProp(chosenWebSettings, 'data.advert', null);

    return {
      room,
      domainId,
      accountId,
      accountPublicId,
      superId,
      groupId,
      dealerName,
      advertName
    };
  };

  /**
   *
   * @param settings
   * @returns {*}
   * @private
   */
  _prepareDefaultSettingsProps = (settings = {}) => {
    if (+getProp(settings, 'cb_offset_horizontal', '0') === 0)
      settings.cb_offset_horizontal = '5';
    if (+getProp(settings, 'cb_offset_vertical', '0') === 0)
      settings.cb_offset_vertical = '5';
    if (+getProp(settings, 'fb_offset_horizontal', '0') === 0)
      settings.fb_offset_horizontal = '5';
    if (+getProp(settings, 'fb_offset_vertical', '0') === 0)
      settings.fb_offset_vertical = '5';
    if (+getProp(settings, 'fb_offline_offset_horizontal', '0') === 0)
      settings.fb_offline_offset_horizontal = '5';
    if (+getProp(settings, 'fb_offline_offset_vertical', '0') === 0)
      settings.fb_offline_offset_vertical = '5';
    if (+getProp(settings, 'fb_mobile_offset_horizontal', '0') === 0)
      settings.fb_mobile_offset_horizontal = '5';
    if (+getProp(settings, 'fb_mobile_offset_vertical', '0') === 0)
      settings.fb_mobile_offset_vertical = '5';
    if (+getProp(settings, 'fb_mobile_offline_vertical', '0') === 0)
      settings.fb_mobile_offline_vertical = '5';
    if (+getProp(settings, 'fb_mobile_offline_offset_horizontal', '0') === 0)
      settings.fb_mobile_offline_offset_horizontal = '5';
    if (+getProp(settings, 'fb_mobile_offline_offset_vertical', '0') === 0)
      settings.fb_mobile_offline_offset_vertical = '5';
    if (!settings.cb_shadow_color) settings.cb_shadow_color = '#000000';
    if (!settings.cb_selection_title)
      settings.cb_selection_title = 'Choose account';

    return settings;
  };

  /**
   * Active
   * @param settings
   * @param meta
   * @private
   */
  componentsActivator = (settings, meta) => {
    const {
      speechBubble = {},
      rooms,
      global: { activeRoom }
    } = store.getState();
    const roomInfo = rooms.find(r => r.room === activeRoom);

    const isInitialMessage = !!SETTING('SB2_INITIAL_MESSAGE', settings);
    const isCBEnabled =
      settings[`cb${isPhone ? '_mobile' : '_desktop'}`] === 'on';

    const isChatActive =
      roomInfo &&
      (roomInfo.waitingOperator ||
        roomInfo.operatorIsAssigned ||
        roomInfo.operatorIsFakeAssigned);
    const sbOnSetting = SETTING('SB2_ENABLED', settings) === 'on';
    const isSBEnabled = sbOnSetting && isInitialMessage && !isChatActive;

    const isCTAEnabled =
      settings[`sb${isPhone ? '_mobile' : ''}_enabled`] === 'on';
    const isCTAShowPermanentlyDesktop =
      settings.sb_show_permanently_desktop === 'on';
    const isCTAShowPermanentlyMobile =
      settings.sb_show_permanently_mobile === 'on';
    const isCTAShowPermanently = isPhone
      ? isCTAShowPermanentlyMobile
      : isCTAShowPermanentlyDesktop;
    if (isCBEnabled) store.dispatch(activateCb());

    if (!isInitialMessage) {
      store.dispatch(deactivateSb());
    } else if (
      speechBubble.active !== statementsInIntegers.DISABLED_ON_DEMAND
    ) {
      store.dispatch(initSb(isSBEnabled));
    }

    if (isCTAEnabled) store.dispatch(activateCTA(isCTAShowPermanently));
    this.configureFBDefaultImage(settings);

    if (
      meta.phone_country_code !== undefined &&
      meta.phone_country_code !== null
    ) {
      store.dispatch(activateContactVia());
    }
  };

  /**
   * Attach system listeners
   *
   * @param {boolean} hasAjaxRouting
   */
  addListeners(hasAjaxRouting) {
    this._onPageReload();

    if (hasAjaxRouting) {
      this._onDomChange();
    }
  }

  /**
   * Do copy of Redux store in localStorage on page reload event
   * @private
   */
  _onPageReload() {
    window.addEventListener('pagehide', () => {
      const formattedStore = ({
        global,
        chatBox,
        floatingButton,
        speechBubble,
        callToAction,
        rooms = []
      }) => {
        return {
          global: this._saveStoreGlobalHandler(global),
          chatBox: this._saveStoreChatBoxHandler(chatBox),
          floatingButton: this._saveStoreFloatingButtonHandler(floatingButton),
          speechBubble: this._saveStoreSpeechBubbleHandler(speechBubble),
          callToAction: this._saveStoreCallToActionHandler(callToAction),
          rooms: this._saveStoreRoomsHandler(rooms)
        };
      };
      saveStore(formattedStore({ ...store.getState() }));
    });
  }

  /**
   *
   * @param global
   * @returns {*}
   * @private
   */
  _saveStoreGlobalHandler = global => {
    global.connection = false;
    global.wasStored = !!global.userId;
    return global;
  };

  /**
   *
   * @param chatBox
   * @returns {*}
   * @private
   */
  _saveStoreChatBoxHandler = chatBox => {
    chatBox.active = false;
    chatBox.onceOpenedInSession = false;
    return chatBox;
  };

  /**
   *
   * @param floatingButton
   * @returns {*}
   * @private
   */
  _saveStoreFloatingButtonHandler = floatingButton => {
    floatingButton.open = false;
    floatingButton.onceOpened = false;
    floatingButton.afterSB = false;
    return floatingButton;
  };

  /**
   *
   * @param speechBubble
   * @returns {*}
   * @private
   */
  _saveStoreSpeechBubbleHandler = speechBubble => {
    speechBubble.dimensionsSet = false;
    speechBubble.open = false;
    return speechBubble;
  };

  /**
   *
   * @param callToAction
   * @returns {*}
   * @private
   */
  _saveStoreCallToActionHandler = callToAction => {
    callToAction.active = false;
    callToAction.onceOpened = false;
    callToAction.open = false;
    return callToAction;
  };

  /**
   *
   * @param rooms
   * @returns {*|Array}
   * @private
   */
  _saveStoreRoomsHandler = (rooms = []) => {
    return rooms.reduce((accumulator, roomData) => {
      let letSkip = false;
      const {
        room,
        domainId,
        accountId,
        accountPublicId,
        superId,
        groupId,
        chatId,
        dealerName,
        advertName,
        status,
        visible,
        needAnswer,
        closingTime,
        sbNextOpenDate,
        mbNextOpenDate,
        ctaNextOpenDate,
        waitingOperator,
        activeRoom,
        lastReadMessagesLength,
        mbInitialMessageTimestamp,
        cbInitialMessageIsSent,
        detailsFormIsSent
      } = roomData;

      if (!letSkip) letSkip = closingTime > 0;

      if (!letSkip) {
        accumulator.push({
          ...initRoom,
          wasStored: true,
          connected: false,
          reinitialized: false,
          room,
          domainId,
          accountId,
          accountPublicId,
          superId,
          groupId,
          chatId,
          dealerName,
          advertName,
          status,
          visible,
          cbInitialMessageIsSent,
          needAnswer,
          sbNextOpenDate,
          mbNextOpenDate,
          ctaNextOpenDate,
          waitingOperator,
          lastReadMessagesLength,
          chatListMessages: [],
          mbInitialMessageTimestamp,
          activeRoom,
          detailsFormIsSent
        });
      }

      return accumulator;
    }, []);
  };

  configureFBDefaultImage = settings => {
    if (
      !SETTING('fb_standard_button', settings) &&
      !settings.fb_svg_width &&
      !settings.fb_svg_height &&
      SETTING('fb_online_button', settings) === 'on' &&
      !settings.fb_custom_button
    ) {
      settings.fb_standard_button = 'on';
    }
  };

  /**
   * Listen for any changes to the DOM and fire a track visitor event if the
   * current URL has changed. Useful for SPA websites.
   * @private
   */
  _onDomChange() {
    let currentLocation = location.href;
    const body = document.querySelector('body');
    const config = { childList: true, subtree: true };
    const observer = new MutationObserver(mutations => {
      mutations.forEach(
        throttle(mutation => {
          if (
            currentLocation !== document.location.href &&
            this.connect &&
            this.connect instanceof ChatConnection
          ) {
            currentLocation = document.location.href;
            this.connect._sendTrackVisitor({ pageUrl: currentLocation });
          }
        }, 5000)
      );
    });

    observer.observe(body, config);
  }
}

export default Configurator;
